import React from "react";

import thubook from "../../assets/images/home/thw-bookimg-1.jpg";

import ttbook from "../../assets/images/home/dd-us-bookimg-1.jpg";

export default function Heartfulnessguide() {
  return (
    <section className="heartfulness-guide-wrpapper pad60">
      <div className="container px-5">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper"> Heartfulness Guidebooks </h3>
            <span className="vertical-blue-line"> </span>
          </div>
          <div className="col-md-6 mobile-mgbottom20">
            <div className="shadow">
              <div className="row">
                <div className="col-sm-4">
                  <img
                    className="img-fluid"
                    src={thubook}
                    alt="Universal Prayer"
                  />
                </div>
                <div className="col-sm-8">
                  <div className="vertical-blk-center py-3 pr-4 mobile-pad-20 ">
                    <h4 className="theme-h4">THE HEARTFULNESS WAY</h4>
                    <p className="theme-p-tag">
                      Heart Based Meditations for Spiritual <br />{" "}
                      Transformation.
                    </p>
                    <p className="theme-p-tag readmore-italic text-right">
                      <u>
                        <a
                          href="https://theheartfulnessway.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn small gotham-medium text-dark"
                        style={{fontWeight:"bold",height:"0px"}}>
                          READ MORE
                        </a>
                      </u>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mobile-mgbottom20">
            <div className="shadow">
              <div className="row">
                <div className="col-sm-4">
                  <img
                    className="img-fluid"
                    src={ttbook}
                    alt="Universal Prayer"
                  />
                </div>
                <div className="col-sm-8">
                  <div className="vertical-blk-center py-3 pr-4 mobile-pad-20">
                    <h4 className="theme-h4"> DESIGNING DESTINY </h4>
                    <p className="theme-p-tag">
                      Heartfulness Practices to Find Your <br /> Purpose and
                      Fulfill Your Potential.
                    </p>
                    <p className="theme-p-tag readmore-italic text-right">
                      <u>
                        <a
                          href="https://www.daaji.org/designing-destiny/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn small gotham-medium text-dark"
                          style={{fontWeight:"bold",height:"0px"}}>
                          READ MORE
                        </a>
                      </u>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
