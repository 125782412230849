import React from "react";
import { Modal } from "react-bootstrap";
// import { StaticImage } from "gatsby-plugin-image";
// import config from "../../data/SiteConfig";
//  import ReactAudioPlayer from 'react-audio-player';
import videoicon from "../../assets/images/home/video-blueicon.png";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal 
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          {/* {config.videodynamic3.map(
                  ({ id, src, title, description }) => ( */}

          <iframe
            // key={config.id}
            src="https://www.youtube.com/embed/0OwY140wrCo"
            title="YouTube video"
            alt="A 3-Minute Guided Meditation | Prayer For Heartfulness Prayer"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="410px"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
          {/* )
                )} */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Audiopopuppage() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span type="button"
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
        className="fl-right"
      >
        <span>
          <img className="fl-right" src={videoicon} alt="videoicon" />
        </span>
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

// render(<Modalpage />);

export default Audiopopuppage;
