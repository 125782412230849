import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import config from "../../assets/config";
// import { Link } from "gatsby";

export default function Quoteday() {
  const [footnote, setFootnote] = useState();
  const [content, setContent] = useState();
  const [displaydtstr, setDisplaydtstr] = useState();
  const todayVal = moment(new Date()).format("YYYY/MM/DD");

  useEffect(() => {
    axios
      .post(config.quoteUrl, {
        mode: "no-cors",
        crossDomain: true,
        Date: todayVal,
        headers: {
          Accept: "application/xml",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/xml; charset=utf-8",
        },
      })
      .then((response) => {
        const quoteData = response.data.data;
        if (quoteData) {
          setFootnote(quoteData.footnote);
          setContent(quoteData.content);
          setDisplaydtstr(
            moment(new Date()).format(
              "dddd,\xa0\xa0MMMM\xa0\xa0DD,\xa0\xa0YYYY"
            )
          );
        }

        return true;
      })
      .catch((error) => {
        return error.message;
      });
  });
  return (
    <div className="subscribe-sec-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-md-left text-center my-5">
            <h3 className="heading-wrapper">One Beautiful Thought</h3>
            <a
             href="https://heartfulness.org/in/subscribe-to-one-beautiful-thought/"
              className="btn btn-blue mg-top20"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              SUBSCRIBE{" "}
            </a>
            {/* <Link to="/" className="btn btn-blue mg-top20">
            SUBSCRIBE
             </Link> */}
          </div>
          <div className="col-md-6 pt-4 text-center">
            <h3 className="heading-wrapper m-0">Quote of the Day</h3>
            <div className="hfn-home-obtsec pt-3">
              <div className="">
                <p className="dr-display-date theme-color">
                {displaydtstr}
                </p>
                <div className="dr-item-content">
                  <p>      {content}</p>
                </div>
                <h4
                  className="theme-h4 theme-color"
                  style={{ fontStyle: "italic" }}
                > {footnote}
                 
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
