import React from "react";

// import { Link } from "gatsby";

import kanhacenter from "../../assets/images/home/kanha.png";

import heartsappim from "../../assets/images/home/heartsapp.png";

import southafricamaster from "../../assets/images/home/southafrica-master-class.png";

import andriodwhite from "../../assets/images/home/android-whiteicon.svg";

import applewhite from "../../assets/images/home/apple-white-icon.svg";

export default function Howstart() {
  return (
    <section className="howstart-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper"> How to Start </h3>
            <span className="vertical-blue-line"> </span>
            <p className="cent-item theme-p-tag fn-p-18">
              {" "}
              Choose any one of the following ways to try Heartfulness
            </p>
          </div>
        </div>

        <div className="row mg-top40">
          <div className="col-md-4">
            <div className="start-box">
              <img src={kanhacenter} alt="Universal Prayer" />
              <div className="start-content-wrapper">
                <h4 className="start-heading"> IN PERSON WITH A TRAINER </h4>
                <p className="theme-p-tag">
                  {" "}
                  Experience meditation, ask questions, and meet other
                  like-minded seekers. There are 6,000 Heartfulness Centers
                  worldwide. To find the HeartSpot near you:
                </p>
                <div className="cent-item mg-top-60-trainer">
                  <a
                    href="https://heartspots.heartfulness.org/"
                    className="btn btn-blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    FIND YOUR TRAINER{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="start-box">
              <img src={heartsappim} alt="Universal Prayer" />
              <div className="start-content-wrapper">
                <h4 className="start-heading">
                  {" "}
                  REMOTELY WITH A TRAINER VIA OUR APP{" "}
                </h4>
                <p className="theme-p-tag">
                  {" "}
                  A Heartfulness session is just a click away! Connect with a
                  certified trainer for a meditation session.
                </p>
                <div className="cent-item mg-top-60-button">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hfn.unified"
                    className="btn btn-blue four-practices-button-pad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <span>
                      {" "}
                      <img src={andriodwhite} alt="Universal Prayer" />{" "}
                    </span>{" "}
                    ANDROID{" "}
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/heartsapp-by-heartfulness/id1438627629"
                    className="btn btn-blue four-practices-button-pad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <span>
                      {" "}
                      <img src={applewhite} alt="Universal Prayer" />{" "}
                    </span>{" "}
                    APP STORE{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="start-box">
              <img src={southafricamaster} alt="Universal Prayer" />
              <div className="start-content-wrapper">
                <h4 className="start-heading">
                  {" "}
                  JOIN MASTERCLASSES IN MEDITATION{" "}
                </h4>
                <p className="theme-p-tag">
                  {" "}
                  In a series of 3 masterclasses, learn the four Heartfulness
                  practices from the Heartfulness Guide, Daaji. With over 40
                  years experience in Meditation, Daaji will guide you on this
                  inspiring journey.
                </p>
                <div className="cent-item">
                  <a href="https://heartfulness.org/in/masterclass/" className="btn btn-blue">
                    {" "}
                    START NOW{" "}
                  </a>
                  {/* <Link to="/"  className="btn btn-blue">
                  START NOW
                      </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
