import React from "react";

import Ralaxman from "../../assets/images/home/hfn-relax-icon.png";

import medidateman from "../../assets/images/home/hfn-meditate.png";

import cleaningman from "../../assets/images/home/hfn-cleaning-icon.png";

import conectman from "../../assets/images/home/hfn-connect-icon.png";

import Relaxaudio from "../../components/home/relaxsationaudio";

import Relaxvideo from "../../components/home/relaxvideo";

import Meditationvideo from "../../components/home/meditationvideo";

import Cleaningvideo from "../../components/home/cleaningvideo";

import Innerconnectvideo from "../../components/home/innerconnect";

import Meditationaudio from "../../components/home/meditationaudio";

import Cleaningaudio from "../../components/home/cleaningaudio";

import Innerconnectaudio from "../../components/home/innerconnectaudio";

import Relaxbuttonvideo from "../../components/home/relaxbuttonvideo";

import Meditationbutvideo from "../../components/home/mediatationbuttonvideo";

import Cleaningbutvideo from "../../components/home/cleanbuttonv";

import Innerconnectbutvideo from "../../components/home/innerconnectbut";

export default function Fourdaily() {
  return (
    <section className="four-daily-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mg-bottom20">
            <h3 className="heading-wrapper theme-color" style={{color:"#2D343A"}}> Four Daily Practices </h3>
            <span className="vertical-blue-line"> </span>
          </div>
          <div className="col-md-3">
            <div className="white-shadow-box">
              
              <img src={Ralaxman} alt="youtube" />
              <div className="practise-text-wrapper">
                <h4 className="practice-heading"> RELAXATION</h4>
                <p className="theme-p-tag pad0-20">
                  {" "}
                  Learning to relax is vital for well-being. It reduces tension
                  in all parts of your body and helps you to stay balanced even
                  in stressful situations.
                </p>
                <div>
                 <Relaxaudio />
                  <Relaxvideo />
                  <Relaxbuttonvideo />
                </div>
               
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="white-shadow-box">
              <img src={medidateman} alt="youtube" />
              <div className="practise-text-wrapper">
                <h4 className="practice-heading"> MEDITATION </h4>
                <p className="theme-p-tag pad0-20">
                  {" "}
                  With regular Heartfulness Meditation, your mind will become centered and shift to deeper levels of feeling, intuition and consciousness.
                </p>
               
                <div>
                 <Meditationaudio />
                  <Meditationvideo />
                <Meditationbutvideo />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="white-shadow-box">
              <img src={cleaningman} alt="youtube" />
              <div className="practise-text-wrapper">
                <h4 className="practice-heading"> CLEANING </h4>
                <p className="theme-p-tag pad0-20">
                  {" "}
                  Cleaning fosters lightness of being, joy and a carefree attitude, as emotional burdens, habits, deep conditioning and complexities are removed.
                </p>
              
                <div>
                 <Cleaningaudio />
                  <Cleaningvideo />
                 <Cleaningbutvideo />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="white-shadow-box">
              <img src={conectman} alt="youtube" />
              <div className="practise-text-wrapper">
                <h4 className="practice-heading">INNER CONNECT</h4>
                <p className="theme-p-tag pad0-20">
                  {" "}
                  Through a simple bedtime prayerful intention, you can connect
                  humbly with your inner self, listen to your heart’s voice, and
                  weave your destiny.
                </p>
                
                <div>
                 <Innerconnectaudio />
                  <Innerconnectvideo />
                 <Innerconnectbutvideo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
