import React from "react";
import { Modal } from "react-bootstrap";

 import config from "../../assets/data/videoitem";

import videoicon from "../../assets/images/home/video-bluebigicon.png";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          {config.cleaningdynamic.map(
                  ({ src, title, description }) => (

          <iframe
           key={src}
           src={src}
            title={title}
            description={description}
            alt="A 3-Minute Guided Meditation | Prayer For Heartfulness Prayer"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="410px"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
           )
                )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Cleaningvideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>

            <span
              onKeyDown={() => setModalShow(true)}
              onClick={() => setModalShow(true)}
              role="button"
              tabIndex="0"
            >
              <img className="mg-right10" src={videoicon} alt="youtube" />
            </span>

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          
    </>
  );
}

export default Cleaningvideo;
