import React from "react";

import { Link } from "gatsby";

import universalprayer from "../../assets/images/home/universal-prayer.png";

export default function Universalprayer() {
  return (
    <section className="universal-wrapper pad60">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <img src={universalprayer} alt="Universal Prayer" />
          </div>

          <div className="col-md-6 universal-sec">
            <h4 className="universal-heading"> UNIVERSAL</h4>
            <h2 className="prayer-heading"> PRAYER</h2>
            <h3 className="nineam-text"> AT 9 PM</h3>

            <p className="universal-prayer-ptag">
              {" "}
              The universal prayer is a daily Heartfulness practice that is
              dedicated to the welfare of all. 
              <br/>
              <br/>
              At 9:00 p.m. sharp, everyone who
              would like to do so, wherever they might be at the time, may stop
              their activities and meditate for fifteen minutes, thinking that
              all brothers and sisters are being filled with love and devotion
              and that real faith is growing stronger in them. It shall be of
              immense value to them, which their practical experience only shall
              reveal.
            </p>
            {/* <a
              href="https://heartfulness.org/in/universal-prayer/"
              className="btn btn-blue"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Know More{" "}
            </a> */}

            <Link to="/universal-prayer/" className="btn btn-blue">
              Know More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
