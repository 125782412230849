import React from "react";

import streesicon from "../../assets/images/home/stress-icon.jpg";

// import videoicon from "../../assets/images/home/video-blueicon.png";

import fearicon from "../../assets/images/home/fear-icon.jpg";

import krodhaicon from "../../assets/images/home/krodha-icon.jpg";

import Audiopopuppage from "../../components/home/detox-audio-popup";

import Stressvideo from "../../components/home/stressvideo";

import Mindrelaxvideo from "../../components/home/mindrelaxsation";

import Limbvideo from "../../components/home/limbcleaning";

import Mediatationrestorevideo from "../../components/home/meditationrestorevideo";

import Fearintrovideo from "../../components/home/fearintrovideo";

import Fearselfstudyvideo from "../../components/home/fearstudy";

import Fearbreathingvideo from "../../components/home/fearbreathing";

import Fearremovevideo from "../../components/home/fearremove";

import Fearseedingvideo from "../../components/home/fearseedingvideo";

import Antitodevideo from "../../components/home/antitode";

import Feargovideo from "../../components/home/feargovideo";

import Detoxangervideo from "../../components/home/detoxanger";

import Regulatingspeechvideo from "../../components/home/regulating-speech";

import Oceanvideo from "../../components/home/ocean-peace";

import Attentionvideo from "../../components/home/relaxsation-attention";

import Removalhabitsvideo from "../../components/home/removal-habits";

import Meditationdetoxvideo from "../../components/home/meditation-detox";

export default function Detoxpractice() {
  return (
    <section className="detox-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mg-bottom20">
            <h3 className="heading-wrapper"> Detox Practices </h3>
            <span className="vertical-blue-line"> </span>
          </div>
        </div>

        <div className="row equal">
          <div className="col-md-4">
            <div className="white-detox">
              <div className="detox-types-wrapper">
                <img className="cent-item" src={streesicon} alt="youtube" />
                <h4
                  className="practice-heading"
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  STRESS DETOX{" "}
                </h4>

                <div className="practise-accord">
                  <p className="detox-p-text">
                    {" "}
                    Why Detox Stress?
                    <Audiopopuppage />
                  </p>

                  <p className="detox-p-text">
                    {" "}
                    Relieve Stress <Stressvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Relaxation
                    <Mindrelaxvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Limb Cleaning <Limbvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Meditation <Mediatationrestorevideo />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="white-detox">
              <div className="detox-types-wrapper">
                <img className="cent-item" src={fearicon} alt="youtube" />
                <h4
                  className="practice-heading"
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  FEAR DETOX{" "}
                </h4>
                <div className="practise-accord">
                  <p className="detox-p-text">
                    {" "}
                    Why Detox Fear? <Fearintrovideo />
                  </p>

                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 1 <Fearselfstudyvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 2
                    <Fearbreathingvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 3 <Fearremovevideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 4 <Fearseedingvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 5 <Antitodevideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Fear Detox 6 <Feargovideo />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="white-detox">
              <div className="detox-types-wrapper">
                <img className="cent-item" src={krodhaicon} alt="youtube" />
                <h4
                  className="practice-heading"
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  ANGER DETOX{" "}
                </h4>
                <div className="practise-accord">
                  <p className="detox-p-text">
                    {" "}
                    Why Detox Anger? <Detoxangervideo />
                  </p>

                  <p className="detox-p-text">
                    {" "}
                    Regulating Speech <Regulatingspeechvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Ocean of Peace" Cleaning
                    <Oceanvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Relaxation and Attention <Attentionvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Removal of Troubling Habits <Removalhabitsvideo />
                  </p>
                  <p className="detox-p-text">
                    {" "}
                    Meditation <Meditationdetoxvideo />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
