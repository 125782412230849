import React from "react";

import Prayervideo from "../../components/home/prayer-video";

import Shiftvideo from "../../components/home/shift-video";

import Kanhavideo from "../../components/home/kanha-video";

import Lonelyvideo from "../../components/home/lonely-video";

import Lifevideo from "../../components/home/life-video";

import Humanvideo from "../../components/home/human-video";

export default function Latestvideos() {
  return (
    <section className="lastest-video-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper"> Latest Videos </h3>
            <span className="vertical-blue-line"> </span>
          </div>
         
        </div>

          <div className="row mg-top40">
          <div className="col-md-4">
             {/* <img src={heartmindvideo} alt="Universal Prayer" /> */}
            <Prayervideo /> 
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> Here’s Why Money Isn’t Making You Happy | The Real Key to Success | Joshua Pollock </h4>
            </div>
            
          </div>
          <div className="col-md-4">
            <Shiftvideo />
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> Unlock the Power of Subconscious Mind | Spiritual Consciousness Through Heartfulness | Daaji </h4>
            </div>
            
          </div>

          <div className="col-md-4">
            <Kanhavideo />
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> An Oasis of Peace | Kanha Shanti Vanam Hyderabad India | Heartfulness </h4>
            </div>
            
          </div>

            

         </div>  

         <div className="row mg-top30">
          <div className="col-md-4">
          <Lonelyvideo />
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> Feeling Lonely vs Being Alone | Heartfulness | Daaji</h4>
            </div>
            
          </div>
          <div className="col-md-4">
            <Lifevideo />
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> Heart versus Mind - Find Balance in your life | Heartfulness | Daaji</h4>
            </div>
            
          </div>

          <div className="col-md-4">
            <Humanvideo />
            <div className="pad-content-video">
            <h4 className="blue-small-border-top mg-top16"> What makes us human? | Science of Yoga | Heartfulness Meditation | Daaji </h4>
            </div>
            
          </div>

            

         </div>  

      </div>
    </section>
  );
}
