import React from "react";

import { Link } from "gatsby";

import kamalashpic from "../../assets/images/home/kamlesh-d-patel-daaji.png";

export default function Dajismile() {
  return (
    <div className="daji-smile-wrapper">
      <div className="container py-4 px-5">
        <div className="shadow-box">
          <div className="row">
            <div className="col-md-5">
              <img
                className="img-fluid"
                src={kamalashpic}
                alt="Universal Prayer"
              />
            </div>
            <div className="col-md-5">
              <div className="daji-describe">
                <h3> <i>Daaji </i></h3>
                <div className="title-blueunderline ml-0"></div>

                <p className="theme-p-tag kamash-pt-ag-pad" >
                  {" "}
                  Kamlesh Patel, also known as Daaji, is the current
                  Heartfulness Guide. He offers a practical, experiential
                  approach to the evolution of consciousness that is simple,
                  easy to follow, and available to people of all ages and walks
                  of life.{" "}
                </p>
                {/* <a
                  href="https://heartfulness.org/in/daaji/"
                  className="btn btn-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Know More{" "}
                </a> */}

                <Link to="/daaji/" className="btn btn-blue">
                Know More
                      </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
