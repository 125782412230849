import React from "react";
import Banner from "../components/home/Banner";
import Fourdaily from "../components/home/fourdaily";

import Detoxpractice from "../components/home/detox-practice";

import Universalprayer from "../components/home/universal-prayer";

import Howstart from "../components/home/how-start";

import Latestvideos from "../components/home/latestvideo";

import Dajismile from "../components/home/daji-smile";

import Heartfulnessguide from "../components/home/heartfulness-guide";

import Quoteday from "../components/home/quoteday";

import Community from "../components/home/community";

import Magazineblue from "../components/home/magazine-blue";

import Heartfulnessoffering from "../components/home/heartfulness-offering";

// import Contacthome from "../components/home/contact";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// markup
const IndexPage = () => {
  return (
    <div>
      <Seo title="Home" />
      <Layout isSticky>
        <section id="home">
          <Banner />
        </section>
        <section>
          <Fourdaily />
        </section>
        <section>
          <Detoxpractice />
        </section>
        <section>
          <Universalprayer />
        </section>
        <section>
          <Howstart />
        </section>
        <section>
          <Latestvideos />
        </section>
        <section>
          <Dajismile />
        </section>
        <section>
          <Heartfulnessguide />
        </section>
        <section>
          <Quoteday />
        </section>
        <section>
          <Community />
        </section>
        <section>
          <Magazineblue />
        </section>
        <section>
          <Heartfulnessoffering />
        </section>
      </Layout>
    </div>
  );
};

export default IndexPage;
