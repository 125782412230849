import React from "react";

import Carousel from "react-multi-carousel";

import BannerImg from "../../assets/images/home/banner1.jpg";

import Bannersecond from "../../assets/images/home/banner2.jpg";
import Banner3 from "../../assets/images/home/banner3.jpg";
import Banner4 from "../../assets/images/home/banner4.jpg";
import Banner5 from "../../assets/images/home/banner5.jpg";
import Banner6 from "../../assets/images/home/banner6.jpg";
import Banner7 from "../../assets/images/home/banner7.jpg";

import "react-multi-carousel/lib/styles.css";

export default function Banner() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}
          </button>
          <button onClick={() => next()}>
            {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="banner-section">
      <div className="image-wrap">
        <Carousel
          arrows={true}
          showDots={true}
          autoPlaySpeed={3000}
          draggable
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={true}
          swipeable
          responsive={responsive}
          customButtonGroup={<ButtonGroup />}
        >
          <div className="banner-height">
            <img data-src={BannerImg} className="lazyload" alt="banner1" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION </h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>

          <div className="banner-height">
            <img data-src={Bannersecond} className="lazyload" alt="banner2" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION</h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>

          <div className="banner-height">
            <img data-src={Banner3} className="lazyload" alt="banner2" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION</h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>

          <div className="banner-height">
            <img data-src={Banner4} className="lazyload" alt="banner2" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION</h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>

          <div className="banner-height">
            <img data-src={Banner5} className="lazyload" alt="banner2" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION</h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>

          <div className="banner-height">
            <img data-src={Banner6} className="lazyload" alt="banner2" />
            <div className="imgover">
              <h4 className="banner-sub-title"> HEARTFULNESS </h4>
              <h3 className="banner-title"> MEDITATION</h3>
              <p className="banner-p-text"> From Stress To Self-Realization</p>
            </div>
          </div>
          
          
          <div className="banner-height">
            <img data-src={Banner7} className="lazyload" alt="banner2" />
         
          </div>

        </Carousel>
      </div>
    </div>
  );
}
