import React from "react";

import cinemeditation from "../../assets/images/home/hfn-heartfullness-cine-meditation.png";

import yoga from "../../assets/images/home/hfn-international-yoga-day.png";

import giftheart from "../../assets/images/home/hfn-gift-of-heart.png";

import { Link } from "gatsby";

export default function Community() {
  return (
    <section className="community-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper" style={{ marginTop: "20px" }}>
              {" "}
              Community{" "}
            </h3>
            <span className="vertical-blue-line"> </span>
            <p className="theme-p-tag community-p-tag" >
              {" "}
              Our Community is made up of people from all cultures, backgrounds
              and walks of life. No matter your beliefs, you are most welcome to
              join our truly international community. Here are some interesting
              stories from some of our community members
            </p>
          </div>
        </div>

        <div className="row mg-top40">
        <div className="col-md-4">
            <div className="post-community-new">
              <img
                className="img-fluid"
                src={cinemeditation}
                alt="Universal Prayer"
              />
              <div className="post-entry-content-one">
                <h4 className="theme-h4 community-h4">
                Heartfulness and Ciné Meditation
                </h4>
                <div className="entry-excerpt-new">
                  <p className="theme-p-tag community-p-tag-18">
                  Reunion is an island located in the west of Indian Ocean, east of Africa, in the southern hemisphere. It is a French department and an overseas region of France.
                  </p>
                </div>
                <hr/>
                <div className="post-author-ino padd-10">
                  <h3 className="post-author-name">Ratnam Kanjee Desriaux</h3>
                  <div className="post-read-more">
                    {" "}
                    <Link
                      className="read-more-black"
                      to={"/hfn-cine-meditation"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="post-community-new">
              <img
                className="img-fluid"
                src={yoga}
                alt="Universal Prayer"
              />
              <div className="post-entry-content-two">
                <h4 className="theme-h4 community-h4"> International Day of Yoga DY 2022 </h4>
                <div className="entry-excerpt-new">
                  <p className="theme-p-tag mb-2 community-p-tag-18">
                  Heartfulness South Africa, on invitation of the Consulate General India- Johannesburg and India High Commission in Pretoria celebrated IDY2022 at Zoo Lake, Johannesburg
                 </p>
                </div>
                <hr/>
                <div className="post-author-ino padd-10">
                  <h3 className="post-author-name">  </h3>
                  <div className="post-read-more">
                    {" "}
                    <Link
                      className="read-more-black"
                      to={"/hfn-international-day"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="post-community-new">
              <img
                className="img-fluid"
                src={giftheart}
                alt="Universal Prayer"
              />
              <div className="post-entry-content-three">
                <h4 className="theme-h4 community-h4">
                  {" "}
                  Gifts of the Heart{" "}
                </h4>
                <div className="entry-excerpt-new">
                  <p className="theme-p-tag community-p-tag-18">
                  It is a great blessing to have been introduced to such a beautiful meditation system. Each time that my heart is swept away in a river of honey, I come back cleansed, and
                </p>
                </div>
                <hr/>
                <div className="post-author-ino padd-10">
                  <h3 className="post-author-name">Anthea</h3>
                  <div className="post-read-more">
                    {" "}
                    <Link
                      className="read-more-black"
                      to={"/hfn-gift-of-the-heart"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<br/>
        <div className="row">
          <div className="col-md-12">
            <div className="cent-item">
              <Link 
                to="/humans-of-heartfulness"
                className="btn btn-blue"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                View All{" "}
              </Link>

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
