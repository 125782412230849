import React from "react";
import { Modal } from "react-bootstrap";
// import { StaticImage } from "gatsby-plugin-image";
// import { config } from "../assets/data/videotem";

import config from "../../assets/data/videoitem";

import lifeout from "../../assets/images/home/life-out.jpg";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video">
          {config.lifevideo.map(({ src, title, description }) => (
            <iframe
              key={src}
              src={src}
              title={title}
              description={description}
              alt="A 3-Minute Guided Meditation | Prayer For Heartfulness Prayer"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              width="100%"
              height="410px"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Lifevideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              onKeyDown={() => setModalShow(true)}
              onClick={() => setModalShow(true)}
              role="button"
              tabIndex="0"
            >
              <img className="img-fluid" src={lifeout} alt="Universal Prayer" />
            </div>

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Lifevideo;
