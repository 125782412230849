import React from "react";

// import maxazineoffering from "../../assets/images/home/magazine-offering-img-1.jpg";

// import { Link } from "gatsby";

import Offeringvideo from "../../components/subpages/offeringdaijivideo";

import Offeringudemyclass from "../../components/subpages/offeringudemyclass";

import Heartsappvideo from "../../components/subpages/heartsappvideo";

import Magazieofferingvideo from "../../components/subpages/mahazineofferingvideo";

import Brighterofferingvideo from "../../components/subpages/brightermindsoffering";

import Schoolofferingvideo from "../../components/subpages/schoolconnectoffering";

import Corporateofferingvideo from "../../components/subpages/corporateoffering";

import Greenofferingvideo from "../../components/subpages/greenoffering";

import Wisdomofferingvideo from "../../components/subpages/wisdomoffering";

export default function Heartfulnessoffering() {
  return (
    <section className="heartfulnessoffering-wrpaper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-3 pt-5 text-left text-md-left">
            <div className="vertical-blk-center">
              <h2 className="gotham-book my-n1">The Heartfulness</h2>
              <h2 className="gotham-book theme-color mb-3">Offerings</h2>
            
              <p className="theme-p-tag">
                Twelve ways to deepen your spiritual awareness, built on 7,000
                years of spiritual wisdom.
              </p>
              {/* <Link to="/" className="btn btn-blue">
                {" "}
                View All{" "}
              </Link> */}
              <a
                     className="btn btn-blue"
                      href="https://heartfulness.org/in/heartfulness-offerings"
                      target="_blank"
                      rel="noreferrer"
                    >
                     View All
                    </a>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              {/* <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                    {/* <img src={maxazineoffering} alt="youtube" /> 

                    <Offeringvideo />
                  </div>
                  <div className="p-4">
                    <h4 className="theme-h4 theme-color text-change">
                    Experience Meditation
                    </h4>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    Daaji&#39;s Masterclasses
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                   <Offeringudemyclass />
                  </div>
                  <div className="p-4">
                    <h4 className="theme-h4 theme-color text-change">
                    Understand Meditation
                    </h4>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    Our Udemy class
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                     <Heartsappvideo /> 
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Meditation on-the-go
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    HeartsApp
                    </p>
                  </div>
                </div>
              </div> */}

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Magazieofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Monthly Heartfulness
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    Heartfulness 
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Brighterofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Child Development
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    Brighter Minds
                    </p>
                  </div>
                </div>
              </div>


              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Schoolofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Heartful Students
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    School-Connect
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Corporateofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Heartfullness 
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                   Corporate
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Greenofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    Kanha Shantivanam
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                    Heartfulness Green
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="offering-box text-center shadow my-3">
                  <div className="home-offer-img">
                  <Wisdomofferingvideo />
                  </div>
                  <div className="p-4">
                    <h5 className="gotham-light theme-color text-change">
                    One Beautiful Thought
                    </h5>{" "}
                    <p className="theme-p-tag m-0 text-change">
                       Wisdom of the Masters
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
